import React, { useRef, useState } from "react"
import { useForm } from "react-hook-form"
import ReCAPTCHA from "react-google-recaptcha"
import { Row, Col } from "../../UI/wrapper"
import Form, { FormGroup, Input, Textarea, Error } from "../../UI/form"
import Button from "../../UI/button"
import { Recaptcha } from "./style"
const RECAPTCHA_KEY = process.env.GATSBY_SITE_RECAPTCHA_KEY
const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const ContactForm = () => {
  const { register, handleSubmit, errors, setValue } = useForm({
    mode: "onChange",
  })
  let captchaRef = useRef(null)

  const [serverState, setServerState] = useState({
    submitting: false,
    status: null,
  })
  const handleServerResponse = (ok, msg, form) => {
    setServerState({
      submitting: false,
      status: { ok, msg },
    })
    if (ok) {
      form.reset()
    }
  }

  const onSubmit = (data, e) => {
    const form = e.target
    setServerState({ submitting: true })
    const captchaValue = captchaRef.current.getValue()
    if (!captchaValue) {
      handleServerResponse(false, "Falta el Captcha", form)
      return
    }
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": "contact",
        "g-recaptcha-response": captchaValue,
        ...data,
      }),
    })
      .then(response => {
        // console.log(response);
        e.target.reset()
        handleServerResponse(true, "Gracias por contactarnos!", form)
      })
      .catch(error => {
        handleServerResponse(false, error, form)
        console.log(error)
      })
  }
  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
      name="contact"
      method="post"
      data-netlify-recaptcha="true"
      netlify-honeypot="bot-field"
      data-netlify="true"
    >
      <Row gutters={{ lg: 20 }}>
        <Col lg={6}>
          <FormGroup mb="20px">
            <Input
              type="text"
              name="name"
              id="name"
              placeholder="Nombre *"
              ref={register({ required: "Nombre es necesario" })}
            />
            <Error>{errors.name && errors.name.message}</Error>
          </FormGroup>
        </Col>
        <Col lg={6}>
          <FormGroup mb="20px">
            <Input type="hidden" name="bot-field" />
            <Input type="hidden" name="form-name" value="contact" />
            <Input
              type="email"
              name="email"
              id="email"
              placeholder="Email *"
              ref={register({
                required: "Email es necesario",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: "email inválido",
                },
              })}
            />
            <Error>{errors.email && errors.email.message}</Error>
          </FormGroup>
        </Col>
      </Row>

      <Row gutters={{ lg: 20 }}>
        <Col lg={6}>
          <FormGroup mb="20px">
            <Input
              type="text"
              name="empresa"
              id="empresa"
              placeholder="Empresa *"
              ref={register({ required: "Nombre es necesario" })}
            />
            <Error>{errors.empresa && errors.empresa.message}</Error>
          </FormGroup>
        </Col>
        <Col lg={6}>
          <FormGroup mb="20px">
            <Input
              type="text"
              name="ruc"
              id="ruc"
              placeholder="RUC *"
              ref={register({
                required: "RUC es necesario",
                pattern: {
                  value: /[0-9]{11}/,
                  message: "RUC inválido",
                },
              })}
            />
            <Error>{errors.ruc && errors.ruc.message}</Error>
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col lg={12}>
          <FormGroup mb="20px">
            <Input
              type="text"
              name="celular"
              id="celular"
              placeholder="Celular *"
              ref={register({ required: "Celular es necesario" })}
            />
            <Error>{errors.celular && errors.celular.message}</Error>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          <FormGroup mb="30px">
            <Textarea
              name="message"
              id="message"
              placeholder="Mensaje *"
              ref={register({
                required: "Mensaje es requerido",
                maxLength: {
                  value: 50,
                  message: "Máximo 50 caracteres",
                },
                minLength: {
                  value: 10,
                  message: "Mínimo 10 caracteres",
                },
              })}
            ></Textarea>
            <Error>{errors.message && errors.message.message}</Error>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <Recaptcha>
            <ReCAPTCHA
              name="g-recaptcha-response"
              ref={captchaRef}
              sitekey={RECAPTCHA_KEY}
              onChange={val => {
                setValue("g-recaptcha-response", val, true)
              }}
            />
          </Recaptcha>
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          <Button type="submit" disabled={serverState.submitting}>
            Enviar Mensaje
          </Button>
          {serverState.status && (
            <p
              className={`form-output ${
                !serverState.status.ok ? "errorMsg" : "success"
              }`}
            >
              {serverState.status.msg}
            </p>
          )}
        </Col>
      </Row>
    </Form>
  )
}

export default ContactForm
