import React from "react"
import SEO from "../components/seo"
import MapaArea from "../containers/contacto/mapa-area"
import InfoArea from "../containers/contacto/info-area"
import FormularioArea from "../containers/contacto/formulario-area"
import Newsletter from "../components/newsletter/newsletter"

const SeccionContacto = () => (
  <>
    <SEO title="Contacto Seggasa" />
    <MapaArea />
    <InfoArea />
    <FormularioArea />
    <Newsletter />
  </>
)

export default SeccionContacto
