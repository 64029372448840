import React from "react"
import PropTypes from "prop-types"
import { FaFacebookSquare, FaWhatsapp, FaInstagram } from "react-icons/fa"
import { Container, Row, Col } from "../../../components/UI/wrapper"
import Heading from "../../../components/Heading"
import ContactForm from "../../../components/forms/contact-form"
import { ContactFormWrapper, LeftBox, IconsWrap } from "./style"
import Social, { SocialLink } from "../../../components/UI/social"

const ContactFormArea = ({ headingStyle, textStyle }) => {
  return (
    <ContactFormWrapper>
      <Container>
        <Row alignitems="center">
          <Col lg={6}>
            <LeftBox>
              <Heading
                {...headingStyle}
                content="Si quieres saber más o tienes alguna consulta, no dudes en contactarnos"
              />
              <IconsWrap>
                <Social varient="outlined" shape="rounded">
                  <SocialLink path="https://www.facebook.com/Seggasa.Llantas.Industriales/">
                    <FaFacebookSquare />
                  </SocialLink>
                  <SocialLink path="https://www.instagram.com/seggasallantasindustriales/">
                    <FaInstagram />
                  </SocialLink>
                  <SocialLink path="https://api.whatsapp.com/send?phone=+51998123024&text=Hola">
                    <FaWhatsapp />
                  </SocialLink>
                </Social>
              </IconsWrap>
            </LeftBox>
          </Col>
          <Col lg={6}>
            <ContactForm />
          </Col>
        </Row>
      </Container>
    </ContactFormWrapper>
  )
}

ContactFormArea.propTypes = {
  headingStyle: PropTypes.object,
  textStyle: PropTypes.object,
}

ContactFormArea.defaultProps = {
  headingStyle: {
    as: "h3",
    position: "relative",
    pl: "34px",
    lineheight: 1.67,
    fontweight: 600,
    child: {
      color: "primary",
    },
    layout: "quote",
  },
}

export default ContactFormArea
