import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const MapaArea = () => {
  const data = useStaticQuery(graphql`
    {
      prismicInformacion(id: { eq: "fbb62bd6-f80d-5b31-ba8a-91b04a10b029" }) {
        data {
          mapa {
            url
          }
        }
      }
    }
  `)
  return (
    <div style={{ height: "500px", width: "100%" }}>
      <iframe
        title="Mapa"
        src={data.prismicInformacion.data.mapa.url}
        width="100%"
        height="450"
        frameborder="0"
        allowfullscreen=""
        aria-hidden="false"
      ></iframe>
    </div>
  )
}

export default MapaArea
