import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { Container, Row, Col } from "../../../components/UI/wrapper"
import Heading from "../../../components/Heading"
import Text from "../../../components/Text"
import { ContactInfoWrapper, InfoBoxWrap, InfoBox } from "./style"

const InfoArea = ({ headingStyle, textStyle, phoneStyle }) => {
  const data = useStaticQuery(graphql`
    {
      prismicInformacion(id: { eq: "fbb62bd6-f80d-5b31-ba8a-91b04a10b029" }) {
        data {
          celular {
            text
          }
          direccion {
            text
          }
          horario_lunes_viernes {
            text
          }
          horario_sabado {
            text
          }
          telefono_fijo {
            text
          }
        }
      }
    }
  `)
  const {
    celular,
    direccion,
    horario_lunes_viernes,
    horario_sabado,
    telefono_fijo,
  } = data.prismicInformacion.data
  return (
    <ContactInfoWrapper>
      <Container>
        <Row>
          <Col lg={4} key="1">
            <InfoBoxWrap>
              <Heading {...headingStyle} content="Dirección" />
              <InfoBox>
                <Text {...textStyle} content={direccion.text} />
                <Text {...textStyle} content="Lima, Perú." />
              </InfoBox>
            </InfoBoxWrap>
          </Col>
          <Col lg={4} key="2">
            <InfoBoxWrap>
              <Heading {...headingStyle} content="Teléfonos" />
              <InfoBox>
                <Text
                  {...phoneStyle}
                  content={`Central de pedidos: ${telefono_fijo.text}`}
                />
                <Text {...phoneStyle} content={`Whatsapp: ${celular.text}`} />
              </InfoBox>
            </InfoBoxWrap>
          </Col>
          <Col lg={4} key="3">
            <InfoBoxWrap>
              <Heading {...headingStyle} content="Horarios" />
              <InfoBox>
                <Text
                  {...textStyle}
                  content={`Lunes – Viernes: ${horario_lunes_viernes.text}`}
                />
                <Text
                  {...textStyle}
                  content={`Sábado: ${horario_sabado.text}`}
                />
              </InfoBox>
            </InfoBoxWrap>
          </Col>
        </Row>
      </Container>
    </ContactInfoWrapper>
  )
}

InfoArea.propTypes = {
  headingStyle: PropTypes.object,
  textStyle: PropTypes.object,
  phoneStyle: PropTypes.object,
}

InfoArea.defaultProps = {
  headingStyle: {
    as: "h5",
    mb: "20px",
  },
  textStyle: {
    mb: "7px",
  },
  phoneStyle: {
    fontweight: 800,
    color: "headingColor",
  },
}

export default InfoArea
