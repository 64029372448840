import styled from "styled-components"
import { device } from "../../../theme"

export const ContactFormWrapper = styled.section`
  padding-top: 100px;
  padding-bottom: 64px;
  @media ${device.medium} {
    padding-top: 80px;
    padding-bottom: 44px;
  }
  @media ${device.small} {
    padding-top: 60px;
    padding-bottom: 25px;
  }
`

export const LeftBox = styled.div`
  ::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0px;
    width: 4px;
    height: 94%;
    transform: translateY(-50%);
    background-color: rgb(210, 169, 142);
  }
  @media ${device.medium} {
    margin-bottom: 45px;
  }
`
export const IconsWrap = styled.div`
  margin-bottom: 60px;
  margin-left: 32px;
  @media ${device.medium} {
    margin-bottom: 50px;
  }
`
